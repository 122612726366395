<template>
    <form ref="form" v-on:submit.prevent="submit" action="/signin" method="post">
        <input type="hidden" name="_token" v-bind:value="csrfToken"/>

        <div class="form-group" v-bind:class="{ 'has-error': 'email' in errors }">
            <label for="email">{{ $t("components.signin_form.email") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-at"></i></span>
                <input id="email" v-model="fields.email" name="email" type="text" class="form-control">
            </div>
            <span v-if="{ 'has-error': 'email' in errors }" class="help-block">{{ errors['email'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'password' in errors }">
            <label for="password">{{ $t("components.signin_form.password") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-unlock-alt"></i></span>
                <input id="password" v-model="fields.password" name="password" type="password" class="form-control">
            </div>
            <span v-if="{ 'has-error': 'password' in errors }" class="help-block">{{ errors['password'] }}</span>
        </div>

        <button type="submit" class="btn btn-success center-block">{{ $t("components.signin_form.submit") }}</button>
    </form>
</template>

<script>
    import Validation from '../validation';

    export default {
        name: "SigninForm",
        props: {
            initialEmail: "",
            initialErrors: null,
        },

        data: function () {
            return {
                csrfToken: "",
                fields: {
                    email: "",
                    password: "",
                },
                errors: {}
            }
        },

        mounted: function () {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            this.fields.email = this.initialEmail;
            if (this.initialErrors) {
                let converted = JSON.parse(this.initialErrors);
                for (let field in converted) {
                    let message = converted[field];
                    this.errors[field] = this.$t(message);
                }
            }
        },

        methods: {
            submit: function (event) {
                this.validate();
                if (Object.keys(this.errors).length === 0) {
                    this.$refs.form.submit();
                }
            },

            validate: function () {
                this.errors = {};

                for(let field in this.fields) {
                    let value = this.fields[field];
                    if (!value) {
                        this.errors[field] = this.$t("validation.is_required");
                    }
                }

                if (this.fields.email && !Validation.validateEmail(this.fields.email)) {
                    this.errors['email'] = this.$t("validation.invalid_email");
                }
            }
        }
    }
</script>
