<template>
    <div>
        <error-display ref="errorHandler"></error-display>
        <table v-if="bookings.length > 0" class="table table-hover">
            <thead>
                <tr>
                    <th>{{ $t('components.booking_list.age_category') }}</th>
                    <th>{{ $t('components.booking_list.person_name') }}</th>
                    <th>{{ $t('components.booking_list.status') }}</th>
                    <th>{{ $t('components.booking_list.actions') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="booking in bookings">
                    <td>{{ translateEnum(resolveEnum(enums.ageCategories, booking.swimming_class.age_category_id)) }}</td>
                    <td>{{ booking.participant_name }}</td>
                    <td>{{ $t('components.booking_list.' + booking.status) }}</td>
                    <td>
                        <div v-for="attachment in booking.attachments">
                            <div v-if="attachment.attachable_type == 'invoice'">
                                <a v-bind:href="'/invoices/' + attachment.attachable.id">{{ $t('components.booking_list.invoice.' + attachment.attachable.invoice_type) }}</a><br/>
                            </div>
                        </div>
                        <a v-if="booking.status !== 'canceled'" v-bind:href="'/courses/' + booking.swimming_class_id">{{ $t('components.booking_list.course') }}</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <span v-else>{{ $t('components.booking_list.no_bookings') }}</span>
    </div>
</template>

<script>
    import ErrorDisplay from "./ErrorDisplay";

    export default {
        name: "BookingList",
        props: {
            bookingsJson: {
                type: String,
                required: true
            }
        },
        data: function() {
            return {
                enums: {},
                bookings: []
            }
        },
        mounted: function() {
            let self = this;
            this.$refs.errorHandler.reset();
            let request = axios.get('/api/enums');
            this.$refs.errorHandler.handleRequest(request, function (result) {
                self.enums.ageCategories = result.data.age_categories;
                self.enums.classCategories = result.data.class_categories;
                self.enums.swimmingPools = result.data.swimming_pools;
                self.bookings = JSON.parse(self.bookingsJson);
            });
        },
        methods: {
            translateEnum: function (item) {
                if (!item) {
                    return "";
                }

                let lang = this.$i18n.locale;
                if (lang && item.i18n[lang]) {
                    return item.i18n[lang];
                }
                return item.name;
            },
            resolveEnum: function (array, id) {
                for (let i = 0; i < array.length; ++i) {
                    if (array[i].id === id) {
                        return array[i];
                    }
                }
                return null;
            },
        },
        components: {
            ErrorDisplay
        }
    }
</script>
