<template>
    <form ref="form" v-on:submit.prevent="submit" action="/signup" method="post">
        <input type="hidden" name="_token" v-bind:value="csrfToken" />

        <div class="form-group" v-bind:class="{ 'has-error': 'first_name' in errors }">
            <label for="first_name">{{ $t("components.registration_form.first_name") }}</label>
            <input v-model="fields.first_name" name="first_name" type="text" class="form-control" id="first_name"/>
            <span v-if="{ 'has-error': 'first_name' in errors }" class="help-block">{{ errors['first_name'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'last_name' in errors }">
            <label for="last_name">{{ $t("components.registration_form.last_name") }}</label>
            <input v-model="fields.last_name" name="last_name" type="text" class="form-control" id="last_name"/>
            <span v-if="{ 'has-error': 'last_name' in errors }" class="help-block">{{ errors['last_name'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'email' in errors }">
            <label for="email">{{ $t("components.registration_form.email_address") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-at"></i></span>
                <input v-model="fields.email" name="email" type="text" class="form-control" id="email"/>
            </div>
            <span v-if="{ 'has-error': 'email' in errors }" class="help-block">{{ errors['email'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'password' in errors }">
            <label for="password">{{ $t("components.registration_form.password") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-unlock-alt"></i></span>
                <input v-model="fields.password" name="password" type="password" class="form-control" id="password"/>
            </div>
            <span v-if="{ 'has-error': 'password' in errors }" class="help-block">
                {{ errors['password'] }}<br>
            </span>
            <span>
                {{ $t("components.registration_form.password_guidelines", [minPasswordLength, maxPasswordLength]) }}
            </span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'passwordConfirmation' in errors }">
            <label for="password_confirmation">{{ $t("components.registration_form.password_confirmation") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-unlock-alt"></i></span>
                <input v-model="fields.passwordConfirmation" name="passwordConfirmation" type="password" class="form-control" id="password_confirmation"/>
            </div>
            <span v-if="{ 'has-error': 'passwordConfirmation' in errors }" class="help-block">{{ errors['passwordConfirmation'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'street' in errors }">
            <label for="street">{{ $t("components.registration_form.street") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-road"></i></span>
                <input v-model="fields.street" name="street" type="text" class="form-control" id="street"/>
            </div>
            <span v-if="{ 'has-error': 'street' in errors }" class="help-block">{{ errors['street'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'housenumber' in errors }">
            <label for="housenumber">{{ $t("components.registration_form.housenumber") }}</label>
            <input v-model="fields.housenumber" name="housenumber" type="text" class="form-control" id="housenumber"/>
            <span v-if="{ 'has-error': 'housenumber' in errors }" class="help-block">{{ errors['housenumber'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'zipcode' in errors }">
            <label for="zipcode">{{ $t("components.registration_form.zipcode") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-map-marker"></i></span>
                <input v-model="fields.zipcode" name="zipcode" type="text" class="form-control" id="zipcode"/>
            </div>
            <span v-if="{ 'has-error': 'zipcode' in errors }" class="help-block">{{ errors['zipcode'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'city' in errors }">
            <label for="city">{{ $t("components.registration_form.city") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-building-o"></i></span>
                <input v-model="fields.city" name="city" type="text" class="form-control" id="city"/>
            </div>
            <span v-if="{ 'has-error': 'city' in errors }" class="help-block">{{ errors['city'] }}</span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'phonenumber' in errors }">
            <label for="phonenumber">{{ $t("components.registration_form.phonenumber") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                <input v-model="fields.phonenumber" name="phonenumber" type="text" class="form-control" id="phonenumber"/>
            </div>
            <span v-if="{ 'has-error': 'phonenumber' in errors }" class="help-block">{{ errors['phonenumber'] }}</span>
        </div>
        <button type="submit" class="btn btn-success center-block">{{ $t("components.registration_form.submit") }}</button>
    </form>
</template>

<script>
    import Validation from '../validation';

    export default {
        name: "RegistrationForm",
        props: {
            initialFirstName: "",
            initialLastName: "",
            initialEmail: "",
            initialCity: "",
            initialZipcode: "",
            initialStreet: "",
            initialHousenumber: "",
            initialPhonenumber: "",
            initialErrors: null,
        },
        data: function () {
            return {
                csrfToken: "",

                fields: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    passwordConfirmation: "",
                    city: "",
                    zipcode: "",
                    street: "",
                    housenumber: "",
                    phonenumber: "",
                },

                minPasswordLength: Validation.minPasswordLength,
                maxPasswordLength: Validation.maxPasswordLength,

                errors: {}
            }
        },
        mounted: function () {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            this.fields.first_name = this.initialFirstName;
            this.fields.last_name = this.initialLastName;
            this.fields.email = this.initialEmail;
            this.fields.city = this.initialCity;
            this.fields.zipcode = this.initialZipcode;
            this.fields.street = this.initialStreet;
            this.fields.housenumber = this.initialHousenumber;
            this.fields.phonenumber = this.initialPhonenumber;
            if (this.initialErrors) {
                let converted = JSON.parse(this.initialErrors);
                for (let field in converted) {
                    let message = converted[field];
                    this.errors[field] = this.$t(message);
                }
            }
        },
        methods: {
            submit: function (event) {
                this.validate();
                if (Object.keys(this.errors).length === 0) {
                    this.$refs.form.submit();
                }
            },

            validate: function () {
                this.errors = {};

                for(let field in this.fields) {
                    let value = this.fields[field];
                    if (!value) {
                        this.errors[field] = this.$t("validation.is_required");
                    }
                }

                if (this.fields.email && !Validation.validateEmail(this.fields.email)) {
                    this.errors['email'] = this.$t("validation.invalid_email");
                }

                let passwordError = Validation.validatePassword(this.fields.password);
                if (passwordError) {
                    this.errors['password'] = this.$t(passwordError.key, passwordError.args);
                } else if (this.fields.passwordConfirmation && this.fields.password.localeCompare(this.fields.passwordConfirmation) !== 0) {
                    this.errors['passwordConfirmation'] = this.$t("validation.password_mismatch");
                }
            }
        }
    }
</script>
