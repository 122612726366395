<template>
    <div>
        <error-display ref="errorHandler"></error-display>

        <div class="courses-thumb">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="courses-top">
                    <div class="courses-image">
                        <img v-bind:src="'/img/thumbnails/age_category_' + course.age_category_id + '.jpg'" class="img-responsive center-block" alt="">
                    </div>
                    <div class="courses-date">
                        <span><i class="fa fa-calendar"></i> {{ $moment(course.first_date_time).local() | moment('dddd') }}, {{ $moment(course.first_date_time).local() | moment('L') }}</span>
                        <span><i class="fa fa-clock-o"></i> {{ $moment(course.first_date_time).local() | moment('LT') }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-12">
                <div class="courses-detail">
                    <h3>
                        <a v-bind:href="'/courses/' + course.id">{{ translateEnum(resolveEnum(enums.ageCategories, course.age_category_id)) }}</a>
                    </h3>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>{{ $t('components.course_details.swimming_pool') }}</td>
                                <td>{{ translateEnum(resolveEnum(enums.swimmingPools, course.swimming_pool_id)) }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('components.course_details.class_category') }}</td>
                                <td>{{ translateEnum(resolveEnum(enums.classCategories, course.class_category_id)) }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('components.course_details.datetimes') }}</td>
                                <td>
                                    <div v-for="item in course.datetimes">
                                            <span>
                                                {{ $moment(item.begin).local() | moment('L') }} {{ $moment(item.begin).local() | moment('LT') }}
                                            </span>
                                        <br/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('components.course_details.class_fee') }}</td>
                                <td>{{ course.class_fee }} € {{ course.pool_fee_inclusive ? $t('components.course_details.pool_fee_inclusive') : $t('components.course_details.pool_fee_exclusive') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorDisplay from "./ErrorDisplay";

    export default {
        name: "ClassDetails",
        props: {
            classJson: String
        },
        data: function () {
            return {
                csrfToken: "",
                parsedBirthday: null,
                enums: {
                    ageCategories: [],
                    classCategories: [],
                    swimmingPools: [],
                },
                course: {}
            }
        },

        mounted: function () {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            window.setTimeout(() => {
                window.jQuery('#personBirthday').datetimepicker({
                    locale: this.$i18n.locale,
                    format: 'L',
                });
            });

            let self = this;
            this.$refs.errorHandler.reset();
            let request = axios.get('/api/enums');
            this.$refs.errorHandler.handleRequest(request, function (result) {
                self.enums.ageCategories = result.data.age_categories;
                self.enums.classCategories = result.data.class_categories;
                self.enums.swimmingPools = result.data.swimming_pools;

                // Load course information __after__ we have all enums.
                // Also class is a reserved specifier which is why we have this naming ambiguity here
                self.course = JSON.parse(self.classJson);
            });
        },

        methods: {
            translateEnum: function (item) {
                if (!item) {
                    return "";
                }

                let lang = this.$i18n.locale;
                if (lang && item.i18n[lang]) {
                    return item.i18n[lang];
                }
                return item.name;
            },
            resolveEnum: function (array, id) {
                for (let i = 0; i < array.length; ++i) {
                    if (array[i].id === id) {
                        return array[i];
                    }
                }
                return null;
            },
        },

        components: {
            ErrorDisplay
        }
    }
</script>
