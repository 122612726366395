/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');

const moment = require('moment-timezone');
moment.tz.setDefault('Europe/Berlin');
require('moment/locale/en-gb');
require('moment/locale/de');

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMoment from 'vue-moment';
import FullCalendar from 'vue-full-calendar';
/**
 * Settings up localization.
 */
// Vue JS
import EnglishTranslations from './lang/en';
import GermanTranslations from './lang/de';
import timeAgo from '../services/timeago';

// Vue Full Calendar
require('fullcalendar/dist/locale-all');

Vue.use(VueI18n);
Vue.use(VueMoment, { moment });
Vue.use(FullCalendar);

const messages = {
    en: EnglishTranslations,
    de: GermanTranslations
};

const i18n = new VueI18n({
    locale: document.documentElement.lang,
    messages,
});

/**
 * Readying Vue JS
 */
window.Vue = Vue;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

/**
 * Register all vue components.
 */
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(files(key).default.name, files(key).default));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    data: {
        user: {}
    },
    mounted: function () {
        this.$moment.locale(document.documentElement.lang);
    },
    i18n,
    timeAgo,
});

$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    })
});

//require('morris.js');
require('jquery-sparkline');
require('jquery-knob');
require('bootstrap-daterangepicker');
require('bootstrap-datepicker');
require('eonasdan-bootstrap-datetimepicker');
require('jquery-slimscroll');
require('fastclick');
