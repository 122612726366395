<template>
    <div class="container">
        <div class="row">
            <error-display ref="errorHandler"></error-display>

            <form>
                <div class="form-group">
                    <label for="age_category">{{ $t('components.class_explorer.age_category') }}</label>
                    <select v-on:change="loadCourses" v-model="ageCategory" id="age_category" class="form-control">
                        <option v-bind:value="null" selected>{{ $t('components.class_explorer.any') }}</option>
                        <option v-for="item in enums.ageCategories" v-bind:value="item.id">{{ translateEnum(item) }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="class_category">{{ $t('components.class_explorer.class_category') }}</label>
                    <select v-on:change="loadCourses" v-model="classCategory" id="class_category" class="form-control">
                        <option v-bind:value="null" selected>{{ $t('components.class_explorer.any') }}</option>
                        <option v-for="item in enums.classCategories" v-bind:value="item.id">{{ translateEnum(item) }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="swimming_pool">{{ $t('components.class_explorer.swimming_pool') }}</label>
                    <select v-on:change="loadCourses" v-model="swimmingPool" id="swimming_pool" class="form-control">
                        <option v-bind:value="null" selected>{{ $t('components.class_explorer.any') }}</option>
                        <option v-for="item in enums.swimmingPools" v-bind:value="item.id">{{ translateEnum(item) }}</option>
                    </select>
                </div>
            </form>
        </div>
        <div class="row">
            {{ $t('components.class_explorer.courses_displayed', [first, last, total]) }}

            <div class="pull-right">
                <div class="btn-group">
                    <button v-on:click="previousPage" type="button" class="btn btn-default btn-sm">«</button>
                    <button v-on:click="nextPage" type="button" class="btn btn-default btn-sm">»</button>
                </div>
            </div>
        </div>

        <span class="separator"></span>

        <div class="row">
            <div v-for="item in classes" class="col-lg-4 col-md-6 col-sm-12">
                <div class="courses-thumb">
                    <div class="courses-top">
                        <div class="courses-image">
                            <a v-bind:href="'/courses/' + item.id">
                                <img v-bind:src="'/img/thumbnails/age_category_' + item.age_category_id + '.jpg'" class="img-responsive center-block" alt="">
                            </a>
                        </div>
                        <div class="courses-date">
                            <span><i class="fa fa-calendar"></i> {{ $moment(item.first_date_time).local() | moment('dddd') }}, {{ $moment(item.first_date_time).local() | moment('L') }}</span>
                            <span><i class="fa fa-clock-o"></i> {{ $moment(item.first_date_time).local() | moment('LT') }}</span>
                        </div>
                    </div>

                    <div class="courses-detail">
                        <h3>
                            <a v-bind:href="'/courses/' + item.id">{{ translateEnum(resolveEnum(enums.ageCategories, item.age_category_id)) }}</a>
                        </h3>
                        <p>
                            {{ $t('components.class_explorer.swimming_pool') }}: {{ translateEnum(resolveEnum(enums.swimmingPools, item.swimming_pool_id)) }}<br/>
                            {{ $t('components.class_explorer.class_category') }}: {{ translateEnum(resolveEnum(enums.classCategories, item.class_category_id)) }}
                        </p>
                    </div>

                    <div class="courses-info">
                        <div class="courses-price">
                            <a v-bind:href="'/courses/' + item.id"><span>{{ item.class_fee }} €</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="text-center">
                    <div class="btn-group">
                        <button v-on:click="previousPage" type="button" class="btn btn-default btn-sm">«</button>
                        <button v-on:click="nextPage" type="button" class="btn btn-default btn-sm">»</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorDisplay from "./ErrorDisplay";

    export default {
        name: "ClassExplorer",
        data: function () {
            return {
                page: 0,
                pageSize: 10,

                enums: {
                    ageCategories: [],
                    classCategories: [],
                    swimmingPools: [],
                },

                total: 0,
                classes: [],

                ageCategory: null,
                classCategory: null,
                swimmingPool: null,
            }
        },

        computed: {
            first: function () {
                if (this.total === 0) return 0;
                return this.page * this.pageSize + 1;
            },
            last: function () {
                if (this.total === 0) return 0;
                return Math.min((this.page + 1) * this.pageSize, this.total);
            }
        },

        mounted: function () {
            let self = this;
            this.$refs.errorHandler.reset();
            let request = axios.get('/api/enums');
            this.$refs.errorHandler.handleRequest(request, function (result) {
                self.enums.ageCategories = result.data.age_categories;
                self.enums.classCategories = result.data.class_categories;
                self.enums.swimmingPools = result.data.swimming_pools;
                self.loadCourses();
            });
        },

        methods: {
            loadCourses: function () {
                let self = this;
                this.$refs.errorHandler.reset();
                let query = '/api/swimming-classes?page=' + this.page + "&page_size=" + this.pageSize;

                if (this.ageCategory !== null) {
                    query += "&age_category=" + this.ageCategory;
                }
                if (this.classCategory !== null) {
                    query += "&class_category=" + this.classCategory;
                }
                if (this.swimmingPool !== null) {
                    query += "&swimming_pool=" + this.swimmingPool;
                }

                let request = axios.get(query);
                this.$refs.errorHandler.handleRequest(request, function (result) {
                    self.classes = result.data.classes;
                    self.total = result.data.total;
                    self.page = result.data.page;
                    self.pageSize = result.data.page_size;
                });
            },

            previousPage: function () {
                if (this.page > 0) {
                    this.page--;
                    this.loadCourses();
                }
            },
            nextPage: function () {
                if (this.last < this.total) {
                    this.page++;
                    this.loadCourses();
                }
            },

            translateEnum: function (item) {
                if (!item) {
                    return "";
                }

                let lang = this.$i18n.locale;
                if (lang && item.i18n[lang]) {
                    return item.i18n[lang];
                }
                return item.name;
            },
            resolveEnum: function (array, id) {
                for (let i = 0; i < array.length; ++i) {
                    if (array[i].id === id) {
                        return array[i];
                    }
                }
                return null;
            }
        },

        components: {
            ErrorDisplay
        }
    }
</script>
