const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 255;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

export default {
    minPasswordLength: MIN_PASSWORD_LENGTH,
    maxPasswordLength: MAX_PASSWORD_LENGTH,
    emailRegex: EMAIL_REGEX,
    validatePassword: function (password) {
        if (password) {
            if (password.length < 8) {
                return {key: "validation.password_too_short", args: [MIN_PASSWORD_LENGTH]};
            } else if (password.length > 255) {
                return {key: "validation.password_too_long", args: [MAX_PASSWORD_LENGTH]};
            } else if (!password.match(/[a-z]/)) {
                return {key: "validation.password_no_lowercase"};
            } else if (!password.match(/[A-Z]/)) {
                return {key: "validation.password_no_uppercase"};
            } else if (!password.match(/[0-9]/)) {
                return {key: "validation.password_no_digit"};
            } else if (!password.match(/[^a-zA-Z0-9]/)) {
                return {key: "validation.password_no_special_character"};
            }

            return null;
        } else {
            return {key: "validation.is_required"};
        }
    },
    validateEmail: function (email) {
        return EMAIL_REGEX.test(email);
    }
}
