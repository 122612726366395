export default {
    test: "TEST",
    template: {
        elements: {
            labels: {},
            navbar: {},
            input: {
                datemask: 'dd/mm/yyyy'
            }
        },
        settings: {
            dateformat: "MM/DD/YYYY",
            timeformat: "HH:mm:ss"
        }
    },
    components: {
        error_display: {
            heading: "Error!",
        },
        registration_form: {
            first_name: "First name",
            last_name: "Last name",
            email_address: "Email address",
            password: "Password",
            password_confirmation: "Password Confirmation",
            city: "City",
            zipcode: "Zipcode",
            street: "Street",
            housenumber: "Housenumber",
            phonenumber: "Phonenumber",

            submit: "Submit",

            password_guidelines: "Passwords must be between {0} and {1} characters long and contain at least one of each of the following: uppercase letters, lowercase letters, digits, special characters."
        },
        reset_password_form: {
            password: "New Password",
            password_confirmation: "New Password Confirmation",
            submit: "Change Password"
        },
        signin_form: {
            email: "Email address",
            password: "Password",
            submit: "Sign In"
        },
        class_explorer: {
            age_category: "Age Category",
            class_category: "Class Category",
            swimming_pool: "Swimming Pool",

            any: "Any",
            courses_displayed: "Showing courses {0} - {1} out of {2}"
        },
        class_table: {
            age_category: "Age Category",
            class_category: "Class Category",
            swimming_pool: "Swimming Pool",
            first_datetime: "First class",
            price: "Price",
            no_datetimes: "Kurseinheiten",
            pool_fee_inclusive: "pool fee included",
            pool_fee_exclusive: "plus pool fee",
            discounted: "Discounted!",
            book: "Book",
            book_now: "Book now!",
            any: "Any",
            courses_displayed: "Displaying classes {0} - {1} of {2}",
            weekday: "Day of week",
            day_0: "Monday",
            day_1: "Tuesday",
            day_2: "Wednesday",
            day_3: "Thursday",
            day_4: "Friday",
            day_5: "Saturday",
            day_6: "Sunday",
        },
        course_details: {
            age_category: "Age Category",
            class_category: "Class Category",
            swimming_pool: "Swimming Pool",
            datetimes: "Dates and Times",
            class_fee: "Class Fee",
        },
        booking_form: {
            person_name: "Participant's name",
            person_birthday: "Participant's birthday",
            medical_notes: "Additional medical notes",
            book_now: "Book now!",
        },
        booking_list: {
            age_category: "Age Category",
            person_name: "Participant",
            status: "Status",
            invoice: {
                regular: "Download confirmation",
                cancellation: "Download cancellation confirmation",
            },
            awaits_payment: "Booked",
            paid: "Booked",
            canceled: "Canceled",
            actions: "Actions",
            course: "Show class",
            no_bookings: "No bookings to be shown.",
        }
    },
    api: {
        errors: {
            client_error: "Could not connect to API.",
            server_error: "The server failed to handle the API call.",
            unauthenticated: "You are not authenticated.",
            unauthorized: "You are not authorized for this action.",
            invalid_arguments: "The specified arguments are invalid.",
            not_found: "The specified resource was not found.",
            not_allowed: "The requested action is not allowed.",
        },
    },
    validation: {
        is_required: "Field is required",
        invalid_email: "Invalid email",
        email_already_in_use: "Email address is already in use",
        password_mismatch: "Passwords do not match",
        password_too_short: "Password too short. A minimum length of {0} is required.",
        password_too_long: "Password too long.  Only up to {0} characters are allowed.",
        password_no_uppercase: "Password must contain at least one uppercase character",
        password_no_lowercase: "Password must contain at least one lowercase character",
        password_no_special_character: "Password must contain at least one special character",
        password_no_digit: "Password must contain at least one digit",
        password_invalid: "Password does not meet guidelines",
        server_rejected: "Rejected by server",
    },
    errors: {
        server_error: "An internal error occurred. Please try again later."
    }
}
