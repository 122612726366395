<template>
    <form ref="form" v-bind:action="'/reset/' + customerId" method="post">
        <input type="hidden" name="_token" v-bind:value="csrfToken" />
        <input type="hidden" name="reset_token" v-bind:value="resetToken" />

        <div class="form-group" v-bind:class="{ 'has-error': 'password' in errors }">
            <label for="password">{{ $t("components.reset_password_form.password") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-unlock-alt"></i></span>
                <input v-model="password" name="password" type="password" class="form-control" id="password"/>
            </div>
            <span v-if="{ 'has-error': 'password' in errors }" class="help-block">
                {{ errors['password'] }}<br>
            </span>
            <span>
                {{ $t("components.registration_form.password_guidelines", [minPasswordLength, maxPasswordLength]) }}
            </span>
        </div>
        <div class="form-group" v-bind:class="{ 'has-error': 'passwordConfirmation' in errors }">
            <label for="password_confirmation">{{ $t("components.reset_password_form.password_confirmation") }}</label>
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-unlock-alt"></i></span>
                <input v-model="passwordConfirmation" name="passwordConfirmation" type="password" class="form-control" id="password_confirmation"/>
            </div>
            <span v-if="{ 'has-error': 'passwordConfirmation' in errors }" class="help-block">{{ errors['passwordConfirmation'] }}</span>
        </div>

        <button v-on:click="submit()" type="button" class="btn btn-success center-block">{{ $t("components.reset_password_form.submit") }}</button>
    </form>
</template>

<script>
    import Passwords from '../validation';

    export default {
        name: "ResetPasswordForm",
        props: {
            customerId: {
                required: true,
            },
            resetToken: {
                type: String,
                required: true
            }
        },
        data: function () {
            return {
                csrfToken: "",

                password: "",
                passwordConfirmation: "",

                minPasswordLength: Passwords.minPasswordLength,
                maxPasswordLength: Passwords.maxPasswordLength,

                errors: {}
            };
        },
        mounted: function () {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        },
        methods: {
            submit: function(event) {
                this.validate();
                if (Object.keys(this.errors).length === 0) {
                    this.$refs.form.submit();
                }
            },
            validate: function() {
                this.errors = {};

                if (!this.password) {
                    this.errors['password'] = this.$t("validation.is_required");
                }
                if (!this.passwordConfirmation) {
                    this.errors['passwordConfirmation'] = this.$t("validation.is_required");
                }

                let passwordError = Passwords.validatePassword(this.password);
                if (passwordError) {
                    this.errors['password'] = this.$t(passwordError.key, passwordError.args);
                } else if (this.passwordConfirmation && this.password.localeCompare(this.passwordConfirmation) !== 0) {
                    this.errors['passwordConfirmation'] = this.$t("validation.password_mismatch");
                }
            }
        }
    }
</script>
